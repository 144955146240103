import React from "react"

const ImgPlaceholder = () => {
  return (
    <svg width="100%" height="100%">
      <rect
        width="100%"
        height="100%"
        style={{
          fill: "#eee",
          strokeWidth: 3,
        }}
      />
    </svg>
  )
}

export default ImgPlaceholder
