import React, { Fragment } from "react"
import { graphql } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import { useSpring, animated } from "react-spring"

import SEO from "../components/seo"
import SingleImage from "../components/photo/singleImage"

import metaImage from "../images/bri-stauss.jpg"

export const query = graphql`
  query PhotoPageQuery {
    allInstagram(
      sort: { fields: timestamp, order: DESC }
      filter: { media_type: { in: ["IMAGE", "CAROUSEL_ALBUM"] } }
    ) {
      edges {
        node {
          id
          media_type
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default ({
  data: {
    allInstagram: { edges: posts },
  },
}) => {
  const springProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })

  return (
    <>
      <SEO
        title="Photo"
        description="Photo's of Bri Stauss"
        type="article"
        image={metaImage}
        pathname="photo"
      />
      <animated.section
        className="p-4 pt-0"
        style={{ marginTop: "86px", ...springProps }}
      >
        <div className="photo-gallery">
          {posts.map(({ node: post }) => {
            return (
              <Fragment key={post.id}>
                <VisibilitySensor
                  intervalDelay={200}
                  partialVisibility={true}
                  offset={{ top: -500, bottom: -500 }}
                >
                  {({ isVisible }) => {
                    if (post.media_type === "IMAGE" && post.localFile) {
                      return <SingleImage isVisible={isVisible} post={post} />
                    }
                    return null
                  }}
                </VisibilitySensor>
              </Fragment>
            )
          })}
        </div>
      </animated.section>
    </>
  )
}
